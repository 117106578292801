import React, { useState } from "react"
import { navigate } from "gatsby"
import useShoppingCart from "../hooks/use-shopping-cart"
import styled from "styled-components"
import StripeProducts from "../components/stripe-products"
import LocalProducts from "../components/local-products"
import AccentedButton from "../components/accented-button"
import CheckoutButton from "../components/checkout-button"
import CartTotals from "../components/cart-totals"
import useIsClient from "../util/rehydration"
import getUserContent from "../../user-content"

const Buffer = styled.div`
  padding-bottom: 15px;
`

const ButtonGrid = styled.div`
  display: flex;
  grid-column-gap: 24px;
  justify-content: center;
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px;
`

function CartButtons(props) {
  return (
    <ButtonGrid>
      {props.showCheckout &&
        <ButtonContainer>
          <CheckoutButton
            setStatus={props.setStatus}
            loading={props.loading}
            setLoading={props.setLoading}
        />
        </ButtonContainer>
      }
      <ButtonContainer>
        <AccentedButton
          onClick={() => navigate("/")}
          data-qa={"HomeButton"}
        >
          Home
        </AccentedButton>
      </ButtonContainer>
    </ButtonGrid>
  )
}

export default function CartPage(props) {
  const BusinessIdentifiers = getUserContent().BusinessIdentifiers

  // For addressing rehydration issue
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  const { isClient } = useIsClient()

  const cart = useShoppingCart()
  const [status, setStatus] = useState(cart.getQuantity() > 0 ? "idle" : "empty-cart")
  const [loading, setLoading] = useState(false)

  const errorContent = (
    <>
      <h2>Error: Failed to redirect to Stripe</h2>
      <p>
        We're having problems communicating with Stripe.
        Please try again soon.
      </p>
      <CartButtons
        showCheckout={false}
        setStatus={setStatus}
        loading={loading}
        setLoading={setLoading} 
      />
    </>
  )

  const emptyContent = (
    <>
      <h2>Your cart is empty!</h2>
      <CartButtons showCheckout={false} setStatus={setStatus} />
    </>
  )

  const regularContent = (
    <>
      <h2>Review your purchase</h2>
      <CartTotals />
      <CartButtons
        showCheckout={true}
        setStatus={setStatus}
        loading={loading}
        setLoading={setLoading} 
      />
      <StripeProducts
        filter={product => !!cart.has(product.id)}
        data-qa={"StripeProductsInCart"}
      />
      <Buffer />
      <LocalProducts
        filter={product => !!cart.has(product.id)}
        data-qa={"LocalProductsInCart"}
      />
      <CartButtons
        showCheckout={true}
        setStatus={setStatus}
        loading={loading}
        setLoading={setLoading} 
      />
    </>
  )

  return (
    <div id="cart-page" role="main">
      <BusinessIdentifiers />
      { !isClient ? regularContent :
        status === "redirect-error" ? errorContent :
        cart.getQuantity() === 0 ? emptyContent :
        regularContent
      }
    </div>
  )

}
